<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="column q-col-gutter-sm">
        <q-select
          outlined
          v-if="user.is_super_user == '1'"
          v-model="selMusyrif"
          label="Musyrif"
          :options="musyrif"
          @input="
            selSiswa = '';
            getListSiswa();
          "
        />
        <q-select outlined v-model="selSiswa" label="Siswa" :options="siswa" />
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section class="q-gutter-sm">
        <q-input
          readonly
          dense
          outlined
          :value="nullfilter(selSiswa.asrama)"
          label="Asrama"
        ></q-input>
        <q-input
          readonly
          dense
          outlined
          :value="nullfilter(selSiswa.kamar)"
          label="Kamar"
        ></q-input>
        <q-input
          readonly
          dense
          outlined
          :value="nullfilter(selSiswa.ortu)"
          label="Orang Tua"
        ></q-input>
        <q-input
          readonly
          dense
          outlined
          :value="nullfilter(selSiswa.kontak)"
          label="Kontak"
        ></q-input>
        <q-input
          readonly
          dense
          outlined
          :value="nullfilter(selSiswa.peringatan)"
          label="Peringatan"
        ></q-input>
      </q-card-section>
      <q-tabs
        v-model="tab"
        no-caps
        class="bg-primary text-white"
        indicator-color="orange"
      >
        <q-tab name="input" label="INPUT" />
        <q-route-tab
          :to="'/hasilrekappembinaan/' + selSiswa.value"
          name="riwayat"
          label="RIWAYAT"
          :disable="selSiswa == ''"
        />
      </q-tabs>
      <q-card-section class="q-gutter-sm">
        <a>Uraian Pembinaan :</a>
        <q-input
          v-model="pembinaan.tanggal"
          type="date"
          filled
          :disable="selSiswa == ''"
        />
        <q-input
          v-model="pembinaan.penjelasan"
          label="Penjelasan Kasus"
          filled
          type="textarea"
          @change="saveable = true"
          :disable="selSiswa == ''"
        />
        <q-input
          v-model="pembinaan.penyelesaian"
          label="Penyelesaian Kasus"
          filled
          type="textarea"
          @change="saveable = true"
          :disable="selSiswa == ''"
        />
        <q-uploader
          ref="uploader"
          :factory="uploadFn"
          :max-files="1"
          auto-upload
          hide-upload-btn
          label="Upload Gambar"
          flat
          bordered
          style="width: 266px;"
          :disable="selSiswa == ''"
        />
        <q-checkbox
          size="sm"
          label="Beri Peringatan"
          v-model="showSP"
          :disable="selSiswa == ''"
        ></q-checkbox>
        <q-select
          outlined
          dense
          stack-label
          v-if="showSP"
          :disable="selSiswa == ''"
          :options="peringatan"
          label="SP"
          v-model="pembinaan.peringatan"
        ></q-select>
      </q-card-section>
      <q-card-actions class="q-pa-none">
        <q-btn
          filled
          color="negative"
          class="full-width"
          @click="simpan()"
          :disable="!saveable"
          >simpan</q-btn
        >
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      uploadURL: process.env.VUE_APP_BASE_URL + "/upload.php",
      saveable: false,
      tab: "input",

      showSP: false,
      peringatan: ["SP 1", "SP 2", "SP 3"],

      user: {},
      musyrif: [],
      selMusyrif: {
        value: null,
      },

      pembinaan: {
        tanggal: "",
        penjelasan: "",
        penyelesaian: "",
        nama_file: "",
      },
      selSiswa: "",
      siswa: [],
      tanggal: {
        status: "hadir",
      },
      searchTerm: "",
    };
  },
  async mounted() {
    await this.getUser();
    if (this.user.is_super_user == "1") {
      await this.getMusyrif();
    } else {
      this.selMusyrif.value = this.user.id;
    }
    if (this.$route.params.id > 0) {
      await this.getSiswaDetail();
    }

    await this.getListSiswa();
    this.pembinaan.tanggal = moment().format("YYYY-MM-DD");
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    nullfilter(data) {
      if (data == null || data == "") {
        return "-";
      }
      return data;
    },
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    uploadFn(files) {
      let theFile = files[0];
      let newFileName =
        moment().format("YYYY-MM-DD-HH-mm-ss") + "_" + files[0].name;
      this.pembinaan.nama_file = newFileName;
      var formData = new FormData();
      formData.append("file", theFile);
      formData.append("newname", newFileName);
      formData.append("asal", "pembinaan");

      this.$http.post("/upload.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    async getListSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(`/penilaian/pembinaan/getsiswa/${this.selMusyrif.value}`)
        .then((resp) => {
          this.siswa = resp.data;
        });
      this.$q.loading.hide();
    },
    async getSiswaDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get("/penilaian/pembinaan/getdetail/" + this.$route.params.id)
        .then((resp) => {
          this.selSiswa = resp.data;
        });
      this.$q.loading.hide();
    },
    simpan() {
      this.pembinaan.id_musyrif = this.user.id;
      if (!this.showSP) {
        this.pembinaan.peringatan = null;
      }
      this.$http
        .post(
          "/penilaian/pembinaan/tambah/" + this.selSiswa.value,
          this.pembinaan
        )
        .then((resp) => {
          this.pembinaan = {
            penjelasan: "",
            penyelesaian: "",

            tanggal: moment().format("YYYY-MM-DD"),
          };
          this.$refs.uploader.reset();
          this.$q.notify({
            message: "Pembinaan Berhasil Disimpan!",
            color: "positive",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
