import { render, staticRenderFns } from "./PenilaianInputPembinaan.vue?vue&type=template&id=119a0823&scoped=true&"
import script from "./PenilaianInputPembinaan.vue?vue&type=script&lang=js&"
export * from "./PenilaianInputPembinaan.vue?vue&type=script&lang=js&"
import style0 from "./PenilaianInputPembinaan.vue?vue&type=style&index=0&id=119a0823&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119a0823",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QSelect,QSeparator,QInput,QTabs,QTab,QRouteTab,QUploader,QCheckbox,QCardActions,QBtn});
